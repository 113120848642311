export default {
    confirmPrompt(_this,handler){
        _this.$prompt('理由','工单确认',{
            confirmButtonText:'确定',
            cancelButtonText:'取消',
            inputType:'textarea',
            inputPlaceholder:'请输入理由',
            inputValidator:(val)=>{
                if(val&&val.length>80){
                    return false
                }
            },
            inputErrorMessage:'长度不能超过80'
        }).then(({value})=>{
            handler(value)
        }).catch(()=>{

        })
    }
}